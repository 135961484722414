import { UploadStatus } from '@air/redux-uploader';

import { FileTrackingItem } from '~/components/FileStatusTrackingPane/FileStatusTrackingPane/FileStatusTrackingPane';
import { SimpleStatus } from '~/components/FileStatusTrackingPane/FileStatusTrackingPaneHeader/FileStatusTrackingPaneHeader';

export interface UploaderPaneListItemSharedProps {
  item: FileTrackingItem;
}

export interface UploadStatusHeaderTitle {
  title: string;
  subtitle?: string;
}

export type FileTrackingStatusHeaderTitles = { [key in SimpleStatus]: UploadStatusHeaderTitle };

export interface HeaderStatusParams {
  pausedCount: number;
  erroredCount: number;
  completedCount: number;
  progressingCount: number;
}

export const progressingStatuses: UploadStatus[] = [
  UploadStatus.preparing,
  UploadStatus.uploading,
  UploadStatus.queued,
];

export const sharedPaneItemThumbnailClassNames = 'mr-3 inline-block h-10 w-10 shrink-0 rounded-sm object-cover';
export const paneListItemClassNames = 'px-2.5 py-1.5 rounded mx-1.5 transition-all duration-200';
