import { UploadStatus } from '@air/redux-uploader';
import { memo } from 'react';

import { ReactComponent as FolderError } from '~/components/FileStatusTrackingPane/folder-with-error.svg';
import { ReactComponent as FolderPause } from '~/components/FileStatusTrackingPane/folder-with-pause.svg';
import { ReactComponent as FolderProgress } from '~/components/FileStatusTrackingPane/folder-with-progress.svg';
import { ReactComponent as FolderSuccess } from '~/components/FileStatusTrackingPane/folder-with-success.svg';

import { sharedPaneItemThumbnailClassNames } from '../shared';

export interface FolderWithStatusProps {
  status: UploadStatus;
}

export const FolderWithStatus = memo(({ status }: FolderWithStatusProps) => {
  const isCompleted = status === UploadStatus['completed'];
  if (isCompleted) return <FolderSuccess className={sharedPaneItemThumbnailClassNames} />;

  const isPaused = status === UploadStatus['paused'];
  if (isPaused) return <FolderPause className={sharedPaneItemThumbnailClassNames} />;

  const isErrored = [UploadStatus['failed'], UploadStatus['rejected']].includes(status);
  if (isErrored) return <FolderError className={sharedPaneItemThumbnailClassNames} />;

  return <FolderProgress className={sharedPaneItemThumbnailClassNames} />;
});

FolderWithStatus.displayName = 'FolderWithStatus';
