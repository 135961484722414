import { UploadStatus } from '@air/redux-uploader';
import { noop } from 'lodash';
import { memo, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { UPLOAD_PANE_WIDTH } from '~/constants/WorkspaceSpacing';

import {
  FileStatusTrackingPaneHeader,
  SimpleStatus,
} from '../FileStatusTrackingPaneHeader/FileStatusTrackingPaneHeader';
import { CompletedPaneListItem } from '../FileStatusTrackingPaneList/CompletedPaneListItem';
import { ErroredPaneListItem } from '../FileStatusTrackingPaneList/ErroredPaneListItem';
import { FileStatusTrackingPaneList } from '../FileStatusTrackingPaneList/FileStatusTrackingPaneList';
import { PausedPaneListItem } from '../FileStatusTrackingPaneList/PausedPaneListItem';
import { ProgressingPaneListItem } from '../FileStatusTrackingPaneList/ProgressingPaneListItem';
import { FileTrackingStatusHeaderTitles, HeaderStatusParams, progressingStatuses } from '../shared';

export type FileTrackingItemBase = {
  ext?: string;
  id: string;
  title: ReactNode;
  sizeInBytes?: number;
  thumbnailSource?: string;
  previewLoader?: ReactNode;
  avatar?: ReactNode;
  subtitle?: string;
  onRowClick?: () => void;
};

export type FileTrackingItem = FileTrackingItemBase & {
  currentBytesUploaded?: number;
  error?: string;
  uploadTimeRemaining?: number;
  status: UploadStatus;
  previewLoader?: ReactNode;
};

export interface FileStatusTrackingPaneProps {
  onPauseAll?: () => void;
  onResumeAll?: () => void;
  onCancelAll?: () => void;
  onRetryAll?: () => void;
  onDismiss: () => void;
  onCancelItem?: (upload: FileTrackingItem) => void;
  onPauseItem?: (upload: FileTrackingItem) => void;
  onResumeItem?: (upload: FileTrackingItem) => void;
  onRetryItem?: (upload: FileTrackingItem) => void;
  items: FileTrackingItem[];
  testId?: string;
  /**
   * Always relying on default `false` as of this commit
   * @see https://github.com/AirLabsTeam/next/blob/1d878de1431c06b04be094791db396b29c514abc/providers/FileTrackingPaneStatusProvider.tsx#L21-L22
   * */
  isExpanded?: boolean;
  disableCancel?: boolean;
  /**
   * Always relying on default `noop` as of this commit
   * @see https://github.com/AirLabsTeam/next/blob/1d878de1431c06b04be094791db396b29c514abc/providers/FileTrackingPaneStatusProvider.tsx#L21-L22
   */
  onExpandToggled?: (isExpanded: boolean) => void;
  getStatusTitles: (params: HeaderStatusParams) => FileTrackingStatusHeaderTitles;
}

const getSummaryTrackingStatus = ({
  erroredCount,
  progressingCount,
  pausedCount,
}: {
  erroredCount: number;
  progressingCount: number;
  pausedCount: number;
}): SimpleStatus => {
  if (pausedCount > 0 && !progressingCount) {
    return SimpleStatus.paused;
  } else if (progressingCount) {
    return SimpleStatus.progressing;
  } else if (erroredCount) {
    return SimpleStatus.errored;
  } else {
    return SimpleStatus.completed;
  }
};

// right margin of uploader pane is 16, so for screens smaller than UPLOAD_PANE_WIDTH set window.innerWidth - 2*this margin
const minWidth = typeof window === 'undefined' ? 0 : window.innerWidth - 32;

export const FileStatusTrackingPane = memo(
  ({
    disableCancel,
    onCancelAll,
    onDismiss,
    onPauseAll,
    onResumeAll,
    onRetryAll,
    items,
    onCancelItem,
    onPauseItem,
    onResumeItem,
    onRetryItem,
    testId,
    isExpanded = false,
    onExpandToggled = noop,
    getStatusTitles,
  }: FileStatusTrackingPaneProps) => {
    /**
     * This effectively does the same thing as if `useState` initial state was set to `false`
     * Through to `toggleUploaderContent` and `setUploaderOpen` in the `useEffect`
     */
    const [isUploaderOpen, setUploaderOpen] = useState(isExpanded);

    const toggleUploaderContent = useCallback(() => {
      setUploaderOpen((isOpen) => {
        onExpandToggled?.(!isExpanded);
        return !isOpen;
      });
    }, [isExpanded, onExpandToggled]);

    useEffect(() => {
      setUploaderOpen(isExpanded);
    }, [isExpanded]);

    const completedItems = items.filter((item) => item.status === UploadStatus.completed);
    const erroredItems = items.filter((item) => item.status === UploadStatus.failed);
    const ongoingItems = items.filter((item) => [...progressingStatuses, UploadStatus.paused].includes(item.status));

    const completedCount = completedItems.length;
    const erroredCount = erroredItems.length;
    const pausedCount = items.filter((upload) => upload.status === UploadStatus.paused).length;
    const progressingCount = ongoingItems.length - pausedCount;

    const status = getSummaryTrackingStatus({ erroredCount, progressingCount, pausedCount });
    const statusTitles = getStatusTitles({ completedCount, erroredCount, pausedCount, progressingCount });
    const { title, subtitle } = statusTitles[status] ?? { title: '', subtitle: '' };

    const completedItemsToDisplay = useMemo(
      () => completedItems.map((item) => <CompletedPaneListItem key={item.id} item={item} />),
      [completedItems],
    );

    const erroredItemsToDisplay = useMemo(
      () =>
        erroredItems.map((item) => (
          <ErroredPaneListItem
            key={item.id}
            item={item}
            onCancel={onCancelItem}
            onRetry={onRetryItem}
            error={item.error ?? ''}
          />
        )),
      [erroredItems, onCancelItem, onRetryItem],
    );

    const ongoingItemsToDisplay = useMemo(
      () =>
        ongoingItems.map((item) =>
          item.status === UploadStatus.paused ? (
            <PausedPaneListItem key={item.id} item={item} onCancel={onCancelItem} onResume={onResumeItem} />
          ) : (
            <ProgressingPaneListItem key={item.id} item={item} onCancel={onCancelItem} onPause={onPauseItem} />
          ),
        ),
      [onCancelItem, onPauseItem, onResumeItem, ongoingItems],
    );

    return (
      <div
        className="rounded bg-surface-1 shadow-[0px_1px_3px_0px_#00000026,0px_0px_2px_0px_#00000040,0px_2px_8px_0px_#00000033]"
        style={{
          width: Math.min(minWidth, UPLOAD_PANE_WIDTH),
        }}
        data-testid={testId}
      >
        <FileStatusTrackingPaneHeader
          disableCancel={disableCancel}
          isUploaderOpen={isUploaderOpen}
          onToggle={toggleUploaderContent}
          onPause={onPauseAll}
          onResume={onResumeAll}
          onCancel={onCancelAll}
          onDismiss={onDismiss}
          status={status}
          title={title}
          subtitle={subtitle}
          className="border-b border-grey-5"
        />

        <FileStatusTrackingPaneList
          completedItems={completedItemsToDisplay}
          erroredItems={erroredItemsToDisplay}
          isUploaderOpen={isUploaderOpen}
          onRetryAll={onRetryAll}
          ongoingItems={ongoingItemsToDisplay}
        />
      </div>
    );
  },
);

FileStatusTrackingPane.displayName = 'UploaderPane';
